import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Client } from 'tmi.js'
import tr from 'googletrans';


function App() {

  const client = new Client({
    channels: [ 'c_rainbow' ]
  });
  
  client.connect();
  
  client.on('message', async (channel, userstate, message, self) => {
    console.log('channel:', channel);
    console.log('userstate:', userstate);
    console.log('message:', message);
    console.log('self:', self);
    // "Alca: Hello, World!"
    //console.log(`${tags['display-name']}: ${message}`);
    const result = await tr(message, {
      to: 'en'
    })
    console.log('result:', result);
  });

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
